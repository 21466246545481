



























import Store from '@/models/Store.model';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class OpeningHoursComponent extends Vue {
  @Prop({ required: true })
  public store!: Store;
  
  private openingHours = [
    { title: 'GENERAL.WEEKDAYS.MONDAY', value: 'monday' },
    { title: 'GENERAL.WEEKDAYS.TUESDAY', value: 'tuesday' },
    { title: 'GENERAL.WEEKDAYS.WEDNESDAY', value: 'wednesday' },
    { title: 'GENERAL.WEEKDAYS.THURSDAY', value: 'thursday' },
    { title: 'GENERAL.WEEKDAYS.FRIDAY', value: 'friday' },
    { title: 'GENERAL.WEEKDAYS.SATURDAY', value: 'saturday' },
    { title: 'GENERAL.WEEKDAYS.SUNDAY', value: 'sunday' },
  ];

  private getTimeIntervalAsString(interval: string[]): string {
    // Replace empty strings with 'Not Specified':
    if (!interval[0] && !interval[1]) {
      return this.$t('GENERAL.NOT_SPECIFIED').toString();
    }
    let intervalString: string[] = [
      !interval[0] ? this.$t('GENERAL.NOT_SPECIFIED').toString() : interval[0],
      !interval[1] ? this.$t('GENERAL.NOT_SPECIFIED').toString() : interval[1]
    ];
    return intervalString.join(Store.TIME_DELIMITER);
  }
}
